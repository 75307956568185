<template>
  <div class="introduced-page">
    <!-- 商会章程 -->
    <MainNav />

    <div class="bannerTabbar"><img src="../../assets/ruleBanner.png" alt=""></div>

    <div class="introduced-wrapper">
      <div style="width: 100%; height: 100%; display: flex; justify-content: center;">
        <div class="title">
          <!-- <div style="padding: 2rem">
            <img class="phoenix" src="../../assets/survey/title01.png" alt="" />
          </div> -->
          <!-- <img class="phoenix" src="../../assets/survey/title01.png" alt="" /> -->
          <div>广东省湖南怀化商会章程</div>
          <!-- <img class="somersault" src="../../assets/survey/title02.png" alt="" /> -->
        </div>
      </div>
      
      <!-- 第一章 总则 -->
      <div style="width: 100%; height: 100%; display: flex; justify-content: center;">
        <div class="article">
          <div class="chapter">第一章 总则</div>
          <div class="line">
            <p>____________</p>
            <p>__________________</p>
          </div>
          <div class="text">第一条 本会的名称：广东省湖南怀化商会</div>
          <div class="text">英文名: GUANGDONG HUNAN  HUAIHUA COMMERCIAL ASSOCIATION</div>
          <div class="text">简称：GHCA</div>
          <div class="text">第二条 本会的性质：本商会是由在广东省的湖南省怀化市籍自然人或法人在广东省投资兴办，经广东省工商行政管理部门登记注册的企业依法自愿发起组成的，以合作互助、共同发展为目的联合性非营利性社会团体。</div>
          <div class="text">第三条 本会的宗旨：遵守宪法、法律、法规和国家政策，遵守社会道德风尚，坚持“怀纳天下、团结互助；化育桑梓，奋发图强”的宗旨，为商会会员建立资源共享、信息互通、互利互惠、互助互动平台，推动会员企业做强做大、促进粤怀两地的经济发展和社会进步。</div>
          <div class="text">第四条 本会接受社团登记管理机关广东省民政厅的监督管理和广东省人民政府相关职能部门的业务指导。</div>
          <div class="text">第五条 本会的活动地域为广东省。</div>
          <div class="text">第六条 本会的住所:广东省深圳市南山区高新技术园北区朗山路16号华瀚科技园A座10楼。</div>
          <img class="gifIcon" src="../../assets/survey/icon.gif" alt="" />
        </div>
      </div>
      <!-- 第二章 业务范围 -->
      <div style="width: 100%; height: 100%; display: flex; justify-content: center;">
        <div class="article">
          <div class="chapter">第二章 业务范围</div>
          <div class="line">
            <p>______________</p>
            <p>_____________________</p>
          </div>
          <div class="text">第七条 本会的业务范围</div>
          <div class="text">（一）投融资服务：会员依法、自愿，建立投融资平台，实现资源共享，帮助会员克服市场、项目、资金等的困难，并共寻求风险低、回报高的合作项目，创造良好的经济效益。</div>
          <div class="text">（二）咨询服务：为会员企业提供技术、商品、管理、品牌推广、会计、投资、人才培训等咨询服务。</div>
          <div class="text">（三）法律及维权服务：接受会员委托处理法律事务，为会员排忧解难，依法维护会员合法利益。</div>
          <div class="text">（四）综合服务：</div>
          <div class="text"><span style="color: #fff;">服务</span>1、帮助会员企业开拓国际国内视野，创造发展商机。</div>
          <div class="text"><span style="color: #fff;">服务</span>2、收集会员意见或建议并向政府有关部门反映，建立会员与政府及其各职能部门之间的联系和沟通渠道，发挥组织协调和桥梁纽带作用。</div>
          <div class="text"><span style="color: #fff;">服务</span>3、促进粤怀两地建立经贸和文化交流，为两地经济、文化发展作出贡献。</div>
          <div class="text"><span style="color: #fff;">服务</span>4、举办展销会、订货会，组织招商引资和经济考察等商务活动。</div>
          <div class="text"><span style="color: #fff;">服务</span>5、开展会员之间的联谊活动，提供会员交流、合作的机会，创建和谐商会，打造“怀商”品牌。</div>
          <div class="text"><span style="color: #fff;">服务</span>6、创办本商会网站、电子杂志、编发纸质的通讯会刊与宣传品。</div>
          <div class="text"><span style="color: #fff;">服务</span>7、接受与承办两地政府及相关部门授权或委托的其它事项。</div>
          <img class="gifIcon" src="../../assets/survey/icon.gif" alt="" />
          <div></div>
        </div>
      </div>
      <!-- 第三章 会员 -->
      <div style="width: 100%; height: 100%; display: flex; justify-content: center;">
        <div class="article">
          <div class="chapter">第三章 会员</div>
          <div class="line">
            <p>____________</p>
            <p>__________________</p>
          </div>
          <div class="text">第八条 本会的会员构成：本会会员为单位会员。</div>
          <div class="text">第九条 申请加入本会的会员，必须具备下列条件：</div>
          <div class="text">（一）拥护本会的章程；</div>
          <div class="text">（二）有加入本会的意愿；</div>
          <div class="text">（三）原籍湖南怀化，在广东省境内兴办企业或从事经营的民营实体单位，关心支持商会发展的相关人士可聘为名誉职务或顾问。</div>
          <div class="text">第十条 会员入会的程序是：</div>
          <div class="text">（一）提出入会申请；</div>
          <div class="text">（二）填写入会申请表；</div>
          <div class="text">（三）由理事会或理事会授权的机构审核通过；</div>
          <div class="text">（四）定期将新入会会员名单报常务理事会及理事会审议通过。</div>
          <div class="text">（五）发给会员证；</div>
          <div class="text">第十一条 会员享有下列权利：</div>
          <div class="text">（一）出席会员（会员代表）大会，参加商会活动，接受商会提供的服务；</div>
          <div class="text">（二）选举权、被选举权和表决权；</div>
          <div class="text">（三）提议案权、建议权和监督权；</div>
          <div class="text">（四）入会自愿，退会自由。</div>
          <div class="text">第十二条 会员履行下列义务：</div>
          <div class="text">（一）遵守本会章程；</div>
          <div class="text">（二）执行本会的决议；</div>
          <div class="text">（三）按规定交纳会费；</div>
          <div class="text">（四）维护本会的合法权益；</div>
          <div class="text">（五）完成本会交办的工作；</div>
          <div class="text">（六）向本会反映情况，提供有关资料、信息。</div>
          <div class="text">第十三条 本会名誉职务：本会可根据工作需要，聘请有关知名人士为商会顾问或名誉职务，不具备会员身份的荣誉职务不具有表决权。</div>
          <div class="text">第十四条 会员缴纳会费的标准（详见入会须知）：</div>
          <div class="text">第十五条 会员退会应书面通知本商会，并交回会员证。会员1年不交纳会费或不参加本商会活动的，视为自动退会。</div>
          <div class="text">第十六条 会员如不遵守本商会章程，将由本会提出批评、教育；如有严重违反本章程的行为，经会员大会（或会员代表大会）表决通过，予以除名。</div>
          <div class="text">第十七条 会员的除名：会员除名由理事会审议后，提交会员大会（或会员代表大会）表决。对会员的除名，须有全体半数以上会员（或参加会员代表会议半数）通过方可除名。</div>
          <img class="gifIcon" src="../../assets/survey/icon.gif" alt="" />
        </div>
      </div>
      <!-- 第四章 组织机构和负责人的产生、罢免 -->
      <div style="width: 100%; height: 100%; display: flex; justify-content: center;">
        <div class="article">
          <div class="chapter">第四章 组织机构和负责人的产生、罢免</div>
          <div class="line">
            <p>____________</p>
            <p>__________________</p>
          </div>
          <div class="text">第十八条 本会由会员组成会员大会（或会员代表大会）。会员数量在100个以上的，可以推选代表组成会员代表大会，会员代表由会员选举产生。会员大会（或会员代表大会）是本商会的最高权力机构，依照国家法律、法规和商会章程的规定行使职权。</div>
          <div class="text">第十九条 会员代表大会的产生方式：会员代表不得少于会员总数20%的比例，但最多不得超过200人，最少不得少于50人，会员代表届满后须重新推选。</div>
          <div class="text">第二十条 会员大会（或会员代表大会）行使下列职权：</div>
          <div class="text">（一）决定商会在法律、法规规定范围内的业务范围和工作职能；</div>
          <div class="text">（二）制订或修改章程；</div>
          <div class="text">（三）制订或修改会费标准；</div>
          <div class="text">（四）制订组织机构的选举办法</div>
          <div class="text">（五）选举或者罢免会长、副会长、秘书长（选任制）、常务理事、理事、监事长、监事；</div>
          <div class="text">（六）审议理事会、监事会的年度工作报告、年度财务预决算报告；（七）审议理事会对会员除名的提议；</div>
          <div class="text">（七）审议理事会对会员除名的提议；</div>
          <div class="text">（八）对商会变更、解散和清算等事项作出决议；</div>
          <div class="text">（九）改变或者撤销理事会不适当的决定；</div>
          <div class="text">（十）决定终止事宜。</div>
          <div class="text">第二十一条 会员大会（或会员代表大会）每届4年（会员大会或会员代表大会每届最长不超过4年）。因特殊情况需提前或延期换届的，须由理事会表决通过，报经社团登记管理机关批准同意。但延期换届最长不超过1年，会员大会（或会员代表大会）每两年至少召开一次会议，会员大会（或会员代表大会）由理事会或常务理事会召集，理事会认为有必要或者五分之一以上的会员（或会员代表）提议，可以召开临时会员大会（或会员代表大会）。</div>
          <div class="text">第二十二条 会员大会（或会员代表大会）必须有全体会员（或会员代表）的三分之二以上出席；其决议应当由全体会员（或会员代表）的过半数通过。会员大会（或会员代表大会）应当对所议事项的决定作会议纪要，并由全体监事签名确认，并向全体会员公告。</div>
          <div class="text">第二十三条 本会选举方式：本会制订或修改章程、会费标准，选举或者罢免会长、副会长、秘书长（选任制）、常务理事、理事、监事长、监事须采取无记名投票方式，对正式候选人可以投赞成票、反对票或者另选他人、也可以投弃权票。</div>
          <div class="text">第二十四条 凡有选举事项的大会，会前监事会必须对会员（或会员代表）资格进行审核，确认选举的合法有效性。</div>
          <div class="text">第二十五条 本会设理事会。理事会为会员大会（或会员代表大会）的常设机构，由会长、副会长、秘书长（选任制）、常务理事、理事组成（理事人数不超过会员或会员代表数的三分之一），在会员大会（或会员代表大会）闭会期间，依照会员大会（或会员代表大会）的决议和商会章程的规定履行职责。本会选举产生不少于9名常务理事，会长、副会长为当然常务理事，副会长为当然理事。</div>
          <div class="text">第二十六条 理事会的职权是：</div>
          <div class="text">（一）筹备和召集会员大会（或会员代表大会）； </div>
          <div class="text">（二）执行会员大会（或会员代表大会）的决议，并向会员大会（或会员代表大会）报告工作；</div>
          <div class="text">（三）决定商会具体的工作业务； </div>
          <div class="text">（四）制定商会的变更、解散和清算等事项的方案；</div>
          <div class="text">（五）决定商会各内部机构的设置，并领导商会内部各机构开展工作；</div>
          <div class="text">（六）制定商会内部管理制度；</div>
          <div class="text">（七）制定商会的年度财务预算、决算方案；</div>
          <div class="text">（八）决定新会员入会和对会员的处分，提议对会员的除名；</div>
          <div class="text">（九）聘任或者解聘聘任制秘书长，决定商会办事机构、实体机构的主要负责人，聘任或者解聘副秘书长，决定其报酬事项； </div>
          <div class="text">（十）商会章程规定的其他事项。</div>
          <div class="text">第二十七条 理事会每半年至少召开一次会议（情况特殊的，也可采用通讯形式召开）。理事会须有过半数的理事出席方能召开，其决议须经全体理事过半数表决通过方能生效。理事会应当对决议形成会议纪要，并由监事签字确认，并向全体理事公告。</div>
          <div class="text"><span style="color: #fff;">组织</span>理事会会议由会长召集和主持；会长因特殊原因不能履行职务时，由会长委托副会长召集和主持。三分之一以上理事可以提议召开理事会。</div>
          <div class="text">第二十八条 理事会人数在50人以上的，根据需要可从理事中选举常务理事，设立常务理事会，常务理事会对理事会负责。常务理事会由会长、副会长、常务理事组成。</div>
          <div class="text"><span style="color: #fff;">组织</span>常务理事会在理事会闭会期间，经理事会授权可以行使本章程第二十六条规定的第一、三、四、五、六、七、八项职权。常务理事会至少3个月召开一次会议；情况特殊的也可采用通讯形式召开。常务理事会须有过半数的常务理事出席方能召开，其决议须经全体参与会议的常务理事过半数表决通过方能生效。常务理事会应当对决议形成会议纪要，并由监事签字确认，并向全体理事公告。</div>
          <div class="text"><span style="color: #fff;">组织</span>常务理事会会议由会长召集和主持；会长因特殊原因不能履行职务时，由会长委托副会长召集和主持。三分之一以上常务理事可以提议召开常务理事会。</div>
          <div class="text">第二十九条 本会设立监事会（其成员不得少于3人），由会员大会（或会员代表大会）选举产生。监事会任期与理事会任期相同，期满可以连任。会长、副会长、理事、秘书长不得兼任监事。监事应当遵守有关法律法规和商会章程，接受会员大会（或会员代表大会）领导，切实履行职责。监事列席理事会会议、常务理事会会议，监督会议程序有效性和表决结果合法性，监督理事会、常务理事会执行会员（会员代表）大会决议情况，向会员（会员代表）大会报告年度监事会工作情况，有权向理事会（或常务理事会）提出建议或向登记管理机关反映情况。</div>
          <div class="text">第三十条 监事会行使下列职权：</div>
          <div class="text">（一）向会员大会（或会员代表大会）报告年度工作；</div>
          <div class="text">（二）监督会员大会（或会员代表大会）和理事会的选举、罢免；监督理事会履行会员大会（或会员代表大会）的决议；</div>
          <div class="text">（三）检查商会财务和会计资料，向登记管理机关以及税务、会计主管部门反映情况；</div>
          <div class="text">（四）监事列席理事会会议，有权向理事会提出质询和建议；</div>
          <div class="text">（五）监督理事会遵守法律和章程的情况，当会长、副会长、理事和秘书长等管理人员的行为损害商会利益时，要求其予以纠正，必要时向会员代表大会或政府相关部门报告。</div>
          <div class="text">第三十一条 召开监事会会议。应有三分之二以上监事出席方能召开，其决议应由全体监事三分之二以上通过方能生效。监事会会议纪要应向全体会员公告。</div>
          <div class="text">第三十二条 商会根据需要可设立会长办公会议制度，会长、副会长及秘书长参加，对常务理事会负责。在常务理事会闭会期间，按章程规定经常务理事会授权可以行使以下职权</div>
          <div class="text">（一）制定年度工作计划和预算，报常务理事会审核；</div>
          <div class="text">（二）部署办事机构、实体机构开展工作；</div>
          <div class="text">（三）提名副秘书长以及各办事机构、实体机构主要负责人，交理事会或常务理事会决定；</div>
          <div class="text">（四）处理章程规定的其他事务</div>
          <div class="text">第三十三条 会长办公会议可根据需要适时召开（情况特殊的也可采用通讯形式召开）。会长办公会议须有半数以上的成员出席方能召开，其决议须经过全体成员半数以上表决通过方能生效。会长办公会议应当对决议形成会议纪要，并向全体常务理事公告。</div>
          <div class="text"><span style="color: #fff;">组织</span>会长办公会议由会长召集和主持；会长因特殊原因不能履行职务时，由会长委托副会长召集和主持。</div>
          <div class="text">第三十四条 商会设会长1人，常务副会长、副会长若干人，秘书长1人。本会会长、副会长、秘书长依照章程的规定履行职责。会长、副会长、选任制秘书长每届任期4年（每届不得超过4年），连任不得超过两届。</div>
          <div class="text"><span style="color: #fff;">组织</span>会长为本商会的法定代表人，不得兼任其他社会团体的法定代表人。商会秘书长以上负责人（包括会长、副会长、秘书长）不得来自于同一单位。</div>
          <div class="text">第三十五条 本会的会长、副会长、秘书长、监事长、监事必须具备下列条件：</div>
          <div class="text">（一）坚持党的路线、方针、政策，遵守国家法律法规；</div>
          <div class="text">（二）在本商会内有较大影响；</div>
          <div class="text">（三）会长、副会长、秘书长最高任职年龄不得超过70周岁，秘书长为专职；</div>
          <div class="text">（四）身体健康，能坚持正常工作；</div>
          <div class="text">（五）未受到任何刑事处罚；</div>
          <div class="text">（六）具有完全民事行为能力。</div>
          <div class="text">第三十六条 本会会长行使下列职权：</div>
          <div class="text">（一）召集和主持理事会（常务理事会）会议、会长办公会议；</div>
          <div class="text">（二）检查会员大会（或会员代表大会）、理事会（或常务理事会）、会长办公会议决议的落实情况；</div>
          <div class="text">（三）代表本会签署有关重要文件。</div>
          <div class="text">第三十七条 本会副会长行使下列职权：</div>
          <div class="text">（一）协助会长做好分管工作；</div>
          <div class="text">（二）定期向会长汇报工作，对会内重大事宜提出意见和建议；</div>
          <div class="text">（三）关心并参与会内的一切活动；</div>
          <div class="text">（四）认真贯彻商会决定、决议，努力配合会长做好非公有制经济人士的思想政治工作；</div>
          <div class="text">（五）完成会长交办的任务。</div>
          <div class="text">第三十八条 本会的秘书长采用选任制（或聘任制），秘书长和会长不能在同一企业中产生。会长不得兼任秘书长。</div>
          <div class="text">第三十九条 本会秘书长在会长领导下开展工作，秘书长对理事会负责。秘书长为专职，行使下列职权：</div>
          <div class="text">（一）主持办事机构开展日常工作，组织实施年度工作计划；</div>
          <div class="text">（二）组织制定、实施年度工作计划和预算；</div>
          <div class="text">（三）协调各办事机构、实体机构开展工作；</div>
          <div class="text">（四）提名办事机构、实体机构专职工作人员的聘用，报理事会或常务理事会批准；</div>
          <div class="text">（五）处理其他日常事务。</div>
          <div class="text"><span style="color: #fff;">组织</span>秘书长出席（或列席）理事会、常务理事会会议、会长办公会议。</div>
          <div class="text">第四十条 理事、监事、常务理事的变更和增补：由商会根据实际情况制订变更和增补方式，本会届中理事、监事的变更和增补必须向全体会员公告，并经会员大会（或会员代表大会）追认。常务理事的变更和增补必须向全体理事公告，并经理事会追认。</div>
          <div class="text">第四十一条 副会长、秘书长（选任制）的变更和增补：本会届中副会长、秘书长（选任制）的变更和增补必须向全体会员公告，并经会员大会（或会员代表大会）追认，并及时填写《社会团体负责人备案表》，向登记管理机关备案。</div>
          <div class="text">第四十二条 本会会长（法定代表人）的变更。本会会长在任期内，调离原单位或因其他原因不能继续担任会长的，会长单位应提前2个月书面报告理事会或常务理事会，并可推荐其他相应人选作为会长继任人选。理事会在收到会长单位报告后，应在30天内召开理事会或常务理事会讨论研究，并提交会员大会（或会员代表大会）无记名投票选举。</div>
          <div class="text"><span style="color: #fff;">组织</span>员大会（或会员代表大会）通过会长变更后，本会应及时向登记管理机关报送变更法定代表人的相关材料。</div>
          <img class="gifIcon" src="../../assets/survey/icon.gif" alt="" />
        </div>
      </div>
      <!-- 第五章 内部管理 -->
      <div style="width: 100%; height: 100%; display: flex; justify-content: center;">
        <div class="article">
          <div class="chapter">第五章 内部管理</div>
          <div class="line">
            <p>____________</p>
            <p>__________________</p>
          </div>
          <div class="text">第四十三条 本会经费来源：</div>
          <div class="text">（一）会费；</div>
          <div class="text">（二）捐赠；</div>
          <div class="text">（三）政府资助；</div>
          <div class="text">（四）在核准的业务范围内开展活动或服务的收入；</div>
          <div class="text">（五）利息；</div>
          <div class="text">（六）其他合法收入。</div>
          <div class="text">第四十四条 本会的财务工作职责和管理：商会的经费审批权限由商会理事会审议核定。具体由理事会制定管理办法。</div>
          <div class="text">第四十五条 本会经费必须用于本章程规定的业务范围和事业的发展，财产以及其他收入受法律保护，任何单位、个人不得侵占、私分和挪用。</div>
          <div class="text">第四十六条 本会会长、副会长、常务理事、理事、监事、秘书长以及工作人员私分、侵占、挪用商会财产的，应当退还，并在会员大会（会员代表大会）上进行检讨；构成犯罪的，依法追究刑事责任。</div>
          <div class="text">第四十七条 本会执行国家统一的会计制度，依法进行会计核算、建立健全内部会计监督制度，保证会计资料合法、真实、准确、完整。本会接受税务、会计主管部门依法实施的税务监督和会计监督。</div>
          <div class="text">第四十八条 本会每年聘请会计师事务所进行财务审计，审计结果向全体会员公告。商会向聘用的会计师事务所提供真实、完整的会计凭证、会计账簿、财务会计报告及其他会计资料，不得拒绝、隐匿、谎报。本会聘用、解聘承办审计业务的会计师事务所，应由理事会表决通过。理事会就解聘会计师事务所进行表决时，允许会计师事务所陈述意见。</div>
          <div class="text">第四十九条 本会配备具有专业资格的会计人员。会计不得兼任出纳。会计人员必须进行会计核算，实行会计监督。会计人员调动工作或离职时，必须与接管人员办清交接手续。</div>
          <div class="text">第五十条 本会的资产管理必须执行国家规定的财务管理制度，接受会员大会（或会员代表大会）和财政部门的监督。资产来源属于国家拨款或者社会捐赠、资助的，必须接受审计机关的监督，并将有关情况以适当方式向会员公布。</div>
          <div class="text">第五十一条 本会接受捐赠时，应当遵守法律法规，不得以任何形式进行摊派或变相摊派。捐赠方、会员、监事有权向商会查询捐赠财产的使用、管理情况，并提出意见和建议。对捐赠方、会员、监事的查询，商会应及时如实答复。</div>
          <div class="text">第五十二条 本会换届、变更法定代表人以及清算，必须聘请会计师事务所进行财务审计。</div>
          <div class="text">第五十三条 本会的印章管理：商会印章、秘书处印章及财务专用章的使用审批权限由商会理事会审议核定，具体由理事会制定管理办法。</div>
          <div class="text">第五十四条 本会专职工作人员实行全员聘任制，面向社会公开招聘，并订立劳动合同。其工资和保险、福利待遇，参照国家对事业单位的有关规定执行。</div>
          <div class="text">第五十五条 本会应于每年5月31日前向登记管理机关报送年检材料及年度财务审计报告，接受登记管理机关的年度检查。</div>
          <div class="text">第五十六条 重大活动备案报告：本会开展重大活动如召开会员大会（或会员代表大会），修改章程，涉及领导机构及负责人的选举，法定代表人和秘书长以上负责人变更等会议；举办大型研讨论坛，组织展览展销活动，创办经济实体，参与竞拍、投资或承接大型项目，开展涉外（包括港澳台地区）活动，接受境外10万元人民币（或等值外币）以上的捐赠或赞助，发生对商会有重大影响的诉讼活动等，应提前30天向登记管理机关作书面报告，并自觉接受相关业务指导单位的指导；本会重大事项备案报告均采用书面形式，主要内容包括：活动的内容、方式、规模、参加人员、时间、地点、经费等方面。</div>
          <div class="text">第五十七条 评比达标表彰备案：商会开展面向商会会员及会员的评比达标表彰活动，须提前60天向登记管理机关备案。</div>
          <div class="text">第五十八条 信息披露工作：确保信息披露的真实、准确、完整、及时、公平，促进本会规范运作，维护会员的合法权益。</div>
          <img class="gifIcon" src="../../assets/survey/icon.gif" alt="" />
        </div>
      </div>
      <!-- 第六章 章程的修改程序 -->
      <div style="width: 100%; height: 100%; display: flex; justify-content: center;">
        <div class="article">
          <div class="chapter">第六章 章程的修改程序</div>
          <div class="line">
            <p>____________</p>
            <p>__________________</p>
          </div>
          <div class="text">第五十九条 对本会章程的修改，须经理事会表决通过后报会员大会（或会员代表大会）审议。</div>
          <div class="text">第六十条 本会修改的章程，须在会员大会（或会员代表大会）通过后30日内，报登记管理机关核准后生效。</div>
          <img class="gifIcon" src="../../assets/survey/icon.gif" alt="" />
        </div>
      </div>
      <!-- 第七章 终止程序及终止后的财产处理 -->
      <div style="width: 100%; height: 100%; display: flex; justify-content: center;">
        <div class="article">
          <div class="chapter">第七章 终止程序及终止后的财产处理</div>
          <div class="line">
            <p>____________</p>
            <p>__________________</p>
          </div>
          <div class="text">第六十一条 本会有以下情形之一，应当终止，并由理事会或常务理事会提出注销动议；</div>
          <div class="text">（一）章程规定的解散事由出现的；</div>
          <div class="text">（二）会员大会或者会员代表大会决议解散的；</div>
          <div class="text">（三）因分立、合并需要解散的；</div>
          <div class="text">（四）因其他原因终止的；</div>
          <div class="text">第六十二条 本会终止动议须经会员大会（或会员代表大会）表决通过，并报社团登记管理机关审查通过。</div>
          <div class="text">第六十三条 本会终止前，须在社团登记管理机关及有关部门指导下成立清算组织，清理债权债务，处理善后事宜。清算期间，不开展清算以外的活动。商会应在清算结束之日起15日内到登记管理机关办理注销登记手续.</div>
          <div class="text">第六十四条 本会经社团登记管理机关办理注销登记手续后即为终止。</div>
          <div class="text">第六十五条 本会终止后的剩余财产，在社团登记管理机关的监督下，按照国家有关规定，用于发展与本会宗旨相关的事业。</div>
          <img class="gifIcon" src="../../assets/survey/icon.gif" alt="" />
        </div>
      </div>
      <!-- 第八章 附则 -->
      <div style="width: 100%; height: 100%; display: flex; justify-content: center;">
        <div class="article">
          <div class="chapter">第八章 附则</div>
          <div class="line">
            <p>____________</p>
            <p>__________________</p>
          </div>
          <div class="text">第六十六条 本章程于2016年08月08日经第三次会员代表大会修改通过。</div>
          <div class="text">第六十七条 本章程的解释权属本会的理事会。</div>
          <div class="text">第六十八条 本章程自社团登记管理机关核准之日起生效。</div>
          <img class="gifIcon" src="../../assets/survey/icon.gif" alt="" />
        </div>
      </div>
    </div>

    <rightNav />
    <Footer />
  </div>
</template>

<script>
import MainNav from "../../components/MainNav/index";
import Footer from "../../components/Footer.vue";
import rightNav from "../../components/RightNav/RightNav";

export default {
  name:"Constitution",
  data() {
    return {
    };
  },
  components: {
    MainNav,
    Footer,
    rightNav,
  },
  mounted(){
  },
  methods:{
  }
};
</script>

<style scoped lang="less">
@import "../../styles/public";
@import "../../styles/state";
.introduced-page {
  font-size: 12px;

  .bannerTabbar{
      font-size: 1rem;
      width: 100%;
      img{
          width: 100%;
          height: 100%;
      }
  }

  .introduced-wrapper {
    margin: 7rem auto;
    text-align: center;
    // 头部样式
    .title {
      width: 40rem;
      padding: 2rem;
      border: 2px solid #f7d580;
      border-radius: 8rem;
      font-size: 2.5rem;
      font-weight: 600;
      text-align: center;
      position: relative;
      .phoenix {
        position: absolute;
        background-color: #fff;
        padding: 0 2rem;
        // margin: 0 2rem;
        top: -3rem;
        left: 0;
      }
      .somersault {
        position: absolute;
        background-color: #fff;
        padding: 0 2rem;
        right: -1.6rem;
        bottom: 0.4rem;
      }
    }
    
    // 介绍章程
    .article {
      width: 90rem;
      border: 1px solid #f32e4c;
      font-size: 1.6rem;
      padding: 4rem 2rem;
      margin-top: 8rem;
      text-align: left;
      position: relative;
      .chapter {
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
      }
      .line {
        margin-bottom: 4rem;
        p {
          margin: 0;
          height: 0.6rem;
          color: #f32e4c;
        }
      }
      .text {
        font-size: 1.8rem;
        line-height: 3rem;
        margin: 0.5rem 0;
      }
      
      // 动图
      .gifIcon {
        position: absolute;
        background-color: #fff;
        padding: 0 1rem;
        right: 1.5rem;
      }
    }
  }

}
</style>